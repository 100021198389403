import { ICardResultV2, ITicketsCustom } from '@main/src/types/map/searchArea';
import { EActivityType } from '@main/src/types/entities/activity';
import { getCurrency } from '@common/helpers';
import { ICurrencyRates } from '@main/src/types/common/currencyRates';
import { ICurrency } from '@main/src/types/common';
import { currencies } from '@app/constants/currencies';
import { DEFAULT_CURRENCY } from '@app/constants/defaults';
import { convertGlobMapCardTypeToType, IMAGE_SIZE, prepareTags } from '@app/api/services/shared/serp/mapper';

export interface IMapperCardsShortParams {
	data: SerpCardsResp;
	locale: string;
	currencyRates?: ICurrencyRates | null;
	currency?: ICurrency | null;
}

export const mapperCardsShort = ({
	data,
	locale,
	currency,
	currencyRates,
}: IMapperCardsShortParams): ICardResultV2[] => {
	return data.cards.map((card): ICardResultV2 => {
		const { card_id, card_point, price, tags, rating } = card.card;
		const type = convertGlobMapCardTypeToType(card.ct) as EActivityType;
		// const title =
		// 	(card.card.title?.auto && card.card.title?.req && `* ${card.card.title.req}`) ||
		// 	card.card.title?.src ||
		// 	'нет названия';
		const title = card.card.title?.req || card.card.title?.src || 'нет названия';
		const images = card.card.images.map(image => {
			if (image.startsWith('https')) {
				return image.replace('{size}', IMAGE_SIZE);
			}

			return `https://img.qvedo.com/images/${image}/Mx2`;
		});
		const preparedCardTags = prepareTags(card.ct, card.card.tags);
		const description = card.card.texts?.req.short || card.card.texts?.src.short || '';
		const city = card.geo.city_name && card.geo.city_name.length > 0 ? card.geo.city_name[0] : '';

		let priceFirstValue;
		let priceFirstCurrency;
		let priceSecondValue;
		const priceCurrencies = Object.keys(price || {});
		if (priceCurrencies.length && currency?.code && price) {
			const currentCurrency =
				currency && !!price[currency.code]
					? currency
					: currencies.find(({ code }) => {
							return price && code in price;
					  });

			if (currency && currencyRates && currentCurrency) {
				const getPriceValue = (code: string, hasPrice?: boolean) => {
					let currentPrice: number;
					if (hasPrice) {
						currentPrice = price[code];
					} else {
						currentPrice = price[currentCurrency.code] * currencyRates[currentCurrency.code][code];
					}
					return Math.ceil(currentPrice / 100);
				};

				if (type === EActivityType.accommodation) {
					if (locale !== 'ru') {
						priceFirstValue = getPriceValue('USD', !!price['USD']);
						priceFirstCurrency = getCurrency('USD');
						priceSecondValue = currency?.code !== 'USD' ? getPriceValue(currency?.code) : null;
					} else {
						priceFirstValue = getPriceValue('RUB', !!price['RUB']);
						priceFirstCurrency = getCurrency('RUB');
						priceSecondValue = currency?.code !== 'RUB' ? getPriceValue(currency?.code) : null;
					}
				} else {
					const [key] = priceCurrencies;
					priceFirstValue = getPriceValue(key);
					priceFirstCurrency = getCurrency(key);
					priceSecondValue = currency?.code !== key ? getPriceValue(currency?.code) : null;
				}
			}
		}

		let priceLevel = null;
		if (type === 'restaurant') {
			const preparedPriceLevel = preparedCardTags.find(f => f.name.startsWith('price_level.'));
			priceLevel = preparedPriceLevel?.name || null;
		}

		// TODO refactor
		const allTickets: MapCardShortTicket[] = card.tickets || [];
		const mainTickets = allTickets.filter(ticket => {
			return ticket.data.c.ticket_type === 'main';
		});

		const ticketsSort = (mainTickets.length ? mainTickets : allTickets).sort((a, b) => {
			return a.data.c.price - b.data.c.price;
		});

		const displayTicket = ticketsSort.length ? ticketsSort[0] : undefined;
		const displayPriceType = displayTicket?.data.c.price_type;

		let originalCurrency = null;
		let originalCurrencyPrice = null;
		let userCurrencyPrice = null;

		if (currencyRates) {
			if (displayTicket?.data?.c?.currency) {
				originalCurrency = getCurrency(displayTicket?.data.c.currency);

				if (originalCurrency?.code !== displayTicket?.data.c.currency) {
					originalCurrencyPrice =
						displayTicket?.data?.c?.price * currencyRates[displayTicket?.data.c.currency][originalCurrency.code];
				}
			}
			if (
				currencyRates &&
				currency &&
				originalCurrency &&
				displayTicket?.data?.c?.price &&
				currency.code !== originalCurrency?.code
			) {
				const currentPrice = originalCurrencyPrice || displayTicket?.data.c.price;
				userCurrencyPrice = Math.ceil(currentPrice * currencyRates[originalCurrency?.code][currency.code]);
			}
		}

		const ticketsData: ITicketsCustom = {
			count: allTickets.length,
			priceTicket: originalCurrencyPrice || displayTicket?.data?.c?.price || 0,
			displayPriceType,
			currency: originalCurrency,
			userCurrency: currency,
			userPrice: userCurrencyPrice,
			tickets: allTickets,
		};

		return {
			card: {
				id: card_id,
				title: title,
				price: {
					value: priceFirstValue,
					currency: priceFirstCurrency,
					userPrice: priceSecondValue,
					userCurrency: currency,
				},
				type,
				// image: images[0],
				priceLevel,
				rating: rating || null,
				// openNow: true,
				// closed: null,
				description,
				city,
				images,
				tags: preparedCardTags,
				// distance: -1,
				priceValue: 111,
				currency: DEFAULT_CURRENCY,
				hasTitleAuto: card.card.title?.auto || false,
				hasDescriptionAuto: false,
				hotelRates: null,
				tickets: ticketsData,
			},
			offers: [],
			address: {},
			info: {},
			supplier: {},
			teams: {},
		};
	});
};
