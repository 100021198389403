import { captureException } from '@sentry/core';
import { ICardResultV2 } from '@main/src/types/map/searchArea';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { ICurrency } from '@main/src/types/common';
import { DEFAULT_CURRENCY } from '@app/constants/defaults';
import { mapperCardsShort } from '@app/api/services/shared/cardsShort/mapper';
import { fetchCurrencyRates } from '@app/api/services/node/currencyRates';

export interface IFetchCardsShortParams {
	cardIds: string[];
	locale: string;
	currency: ICurrency;
}

export const fetchCardsShort = async ({
	cardIds,
	locale,
	currency,
}: IFetchCardsShortParams): Promise<null | ICardResultV2[]> => {
	const api = await getBrowserHttpClient();
	try {
		if (cardIds.length === 0) {
			return [];
		}

		const currencyRates = await fetchCurrencyRates();
		const result = await api.post<SerpCardsResp, SerpCardsReq>('/client/serp/short-cards', {
			lang: locale,
			ids: cardIds.map(cardId => ({
				card_id: cardId,
			})),
		});

		return mapperCardsShort({
			data: result,
			locale,
			currency,
			currencyRates,
		});
	} catch (error) {
		captureException(error);

		return null;
	}
};
